<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      title="Customers"
      description="Manage your customers"
    >
      <ul class="nk-block-tools g-1 pr-3">
        <li class="nk-block-tools-opt">
          <a
            href="#"
            title="Add new customer"
            class="btn btn-white btn-dim btn-outline-light"
            @click.prevent="Toggle = true"
          >
            <em class="dd-indc icon ni ni-plus"></em>
            <span class="d-inline">New customer</span>
            <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
          </a>
          <AddCustomer
            :toggle-by="Toggle"
            @oncancel="Toggle = false"
            @onsave="Toggle = false"
            title="Add new customer"
          ></AddCustomer>
        </li>
      </ul>
    </BasePageHeader>
    <div class="card-inner">
      <div class="px-1">
        <CustomerList id="customerList" :customers="Customers" />
      </div>
    </div>
  </BasePageContent>
</template>
<script>
import { ref, computed } from "vue";
import CustomerList from "@/components/ui/customer/CustomerList.vue";
import AddCustomer from "@/components/ui/customer/AddCustomer.vue";
import { Util } from "@/helpers/utilities";

import { useStore } from "vuex";
export default {
  components: {
    CustomerList,
    AddCustomer,
  },
  props: {
    addNew: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const title = ref("Customers");
    const Customers = computed(() => store.getters["customers/customers"]);
    const Toggle = ref(props.addNew);
    return {
      title,
      Util,
      Customers,
      Toggle,
    };
  },
};
</script>