<template>
  <template v-if="!Util.isEmpty(Customers)">
    <DataTable :id="ID" class="table-hover table-lg" :options="Options">
      <thead>
        <tr class="nk-tb-item nk-tb-head">
          <th class="nk-tb-col nk-tb-col-check">
            <div
              class="custom-control custom-control-sm custom-checkbox notext"
            >
              <input type="checkbox" class="custom-control-input" id="uid" />
              <label class="custom-control-label" for="uid"></label>
            </div>
          </th>
          <th class="nk-tb-col" style="min-width: 200px">Customer name</th>
          <th class="nk-tb-col tb-col-mb">Receivables</th>
          <th class="nk-tb-col tb-col-lg">Telephone</th>
          <th class="nk-tb-col tb-col-md">Email</th>
          <th class="nk-tb-col nk-tb-col-tools text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="nk-tb-item"
          v-for="customer in Customers"
          :key="customer.uuid"
        >
          <td class="nk-tb-col nk-tb-col-check">
            <div
              class="custom-control custom-control-sm custom-checkbox notext"
            >
              <input type="checkbox" class="custom-control-input" id="uid1" />
              <label class="custom-control-label" for="uid1"></label>
            </div>
          </td>
          <td class="nk-tb-col">
            <router-link
              :to="{ name: 'customer-detail', params: { uuid: customer.uuid } }"
              class="cursor"
            >
              <div class="user-card">
                <div class="user-avatar bg-dim-primary d-none d-sm-flex">
                  <img
                    :src="customer.customer_photo_url"
                    :alt="customer.customer_name"
                    class="rounded-circle"
                  />
                </div>
                <div class="user-info">
                  <div class="size15 nowrap ellipsis">
                    {{ customer.customer_name + " " }}
                  </div>
                  <div class="size12 w3-text-grey ellipsis">
                    <span class="d-none"> (</span>
                    {{ customer.fullname }}
                    <span class="d-none">)</span>
                  </div>
                </div>
              </div>
            </router-link>
          </td>
          <td class="nk-tb-col tb-col-md" v-html="Util.Currency('0.00')"></td>
          <td class="nk-tb-col tb-col-lg ellipsis">
            <a
              v-if="customer.mobile"
              :href="'tel:' + customer.mobile"
              target="_blank"
              class="d-block"
              >{{ customer.mobile }}
            </a>
            <a
              v-if="customer.telephone"
              :href="'tel:' + customer.email"
              target="_blank"
              class="d-block"
              >{{ customer.telephone }}
            </a>
          </td>
          <td class="nk-tb-col tb-col-md ellipsis">
            <a
              v-if="customer.email"
              :href="'mailto:' + customer.email"
              target="_blank"
              >{{ customer.email }}
            </a>
          </td>
          <td class="nk-tb-col nk-tb-col-tools">
            <ul class="nk-tb-actions gx-1">
              <li>
                <div class="drodown">
                  <a
                    href="#"
                    class="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                    ><em class="icon ni ni-more-h"></em>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="link-list-opt no-bdr">
                      <li>
                        <a
                          title="Create New Invoice"
                          :data-uuid="customer.uuid"
                          href="#"
                        >
                          <em class="icon fas fa-file-invoice"></em>
                          <span>New Invoice</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Create New Estimate"
                          :data-uuid="customer.uuid"
                          href="#"
                        >
                          <em class="icon fas fa-file-alt"></em>
                          <span>New Estimate</span></a
                        >
                      </li>
                      <li class="divider"></li>
                      <li>
                        <a
                          title="Email Customer"
                          :data-uuid="customer.uuid"
                          @click.prevent="DeleteDetail(customer.uuid)"
                          href="#"
                        >
                          <em class="icon ni ni-mail-fill"></em>
                          <span>Email Customer</span></a
                        >
                      </li>
                      <li>
                        <router-link
                          title="Customer Detail"
                          :to="{
                            name: 'customer-detail',
                            params: { uuid: customer.uuid },
                          }"
                          class="cursor"
                        >
                          <em class="icon ni ni-eye"></em>
                          <span>Customer Detail</span></router-link
                        >
                      </li>
                      <li>
                        <a
                          title="Edit"
                          :data-uuid="customer.uuid"
                          @click.prevent="QuickEdit(customer.uuid)"
                          href="#"
                        >
                          <em class="icon fas fa-edit"></em>
                          <span>Edit Customer</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Edit"
                          :data-uuid="customer.uuid"
                          @click.prevent="DeleteDetail(customer.uuid)"
                          href="#"
                        >
                          <em class="icon fas fa-trash"></em>
                          <span>Delete Customer</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </DataTable>
  </template>
  <div v-else class="p-5 w3-center">
    <img src="/images/item-not-found.png" class="mb-4" />
    <h4>No Item was found</h4>
    <a
      href="#"
      title="Add new customer"
      class="btn btn-white btn-dim btn-outline-light"
      @click.prevent="addCustomer = true"
    >
      <em class="dd-indc icon ni ni-edit"></em>
      <span class="d-none d-md-inline">Add a new customer</span>
    </a>
  </div>
  <AddCustomer
    :toggle-by="addCustomer"
    @oncancel="addCustomer = false"
    @onsave="addCustomer = false"
    title="Add new item"
  ></AddCustomer>
  <Aside
    :toggle-by="DoEdit"
    @oncancel="OnCancel"
    cancel-text="Cancel"
    width="1024px"
  >
    <CustomerUpdate
      tabbed
      v-if="DoEdit"
      :customer="Customer"
      @onsave="OnSave"
      @oncancel="OnCancel"
    />
  </Aside>
</template>
<script>
import {
  // computed,
  ref,
  toRef,
} from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Util } from "@/helpers/utilities";
import DataTable from "@/components/ui/DataTable.vue";
import CustomerUpdate from "@/components/ui/customer/CustomerUpdate.vue";
import AddCustomer from "@/components/ui/customer/AddCustomer.vue";
import Aside from "@/components/ui/Aside.vue";
export default {
  components: {
    DataTable,
    AddCustomer,
    CustomerUpdate,
    Aside,
  },
  props: {
    id: {
      type: String,
    },
    customers: {
      type: Array,
    },
  },

  setup(props) {
    const store = useStore();
    const ID = props.id ? props.id : uuidv4();
    const Customers = toRef(props, "customers");
    const addCustomer = ref(false);
    const Options = {
      pageLength: 25,
      responsive: false,
      order: [[1, "asc"]],
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [0],
        },
      ],
    };

    const DeleteDetail = (uuid) => {
      console.log("DeleteDetail", uuid);
    };
    const Customer = ref({});
    const DoEdit = ref(false);
    const QuickEdit = async (uuid) => {
      const _customer = await store.dispatch("customers/getCustomer", uuid);
      Customer.value = {
        ..._customer,
      };
      DoEdit.value = true;
    };
    const OnCancel = () => {
      Customer.value = {};
      DoEdit.value = false;
    };
    const OnSave = () => {
      OnCancel();
    };
    return {
      store,
      ID,
      Customer,
      Customers,
      addCustomer,
      QuickEdit,
      Util,
      Options,
      OnSave,
      DeleteDetail,
      DoEdit,
      OnCancel,
    };
  },
};
</script>